<template>
  <div class="AsomTable__Container">
    <div
      v-if="filterable || pagination"
      class="AsomTable__FilterAndSelectContainer"
    >
      <div class="AsomTable__FilterContainer">
        <asom-input-text
          v-if="filterable"
          :placeholder="filterPlaceholder"
          v-model="query"
        />
      </div>
      <div class="AsomTable__ItemsPerPageSelectContainer">
        <p class="AsomTable__Label" v-if="pagination">{{ paginationSelectLabel }}</p>
        <asom-input-select
          class="AsomTable__ItemsPerPageSelect"
          v-if="pagination"
          :objectModelValue="false"
          v-model="computedInnersItemPerPage"
          :options="paginationSelectableOptions"
          placeholder=""
          tagPlaceholder=""
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
        />
      </div>
    </div>
    <div class="AsomTable__TableContainer">
      <table
        :class="[
          'AsomTable divide-y divide-gray-200',
          striped && 'AsomTable--striped',
          tableClasses,
        ]"
      >
        <caption class="hidden"></caption>
        <thead>
          <tr>
            <th
              v-for="(c, i) in columns"
              :key="i"
              scope="col"
              @click="onColumnClicked(c)"
              :class="getThClasses(c)"
            >
              <div>
                <slot :name="`header_${c}`" :column="c">
                  {{ columnText(c) }}
                </slot>
                <asom-icon
                  v-if="sortedData.column === c"
                  :icon="sortedData.ascending ? 'sort-az' : 'sort-za'"
                  size="sm"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, i) in shownData" :key="i">
            <td
              v-for="(c, i2) in columns"
              :asoms-table-label="columnText(c)"
              :key="`r_${i}_c_${i2}`"
              :class="[getTdClasses(c, r, i)] && isDeleted(r)"
            >
              <slot
                :name="c"
                :column="c"
                :data="cellText(c, r)"
                :rowIndex="firstShownItemIndex + i"
                :rowData="r"
              >
                {{ cellText(c, r) }}
              </slot>
            </td>
          </tr>
        </tbody>
        <slot name="tfoot"></slot>
      </table>
    </div>
    <div v-if="pagination" class="AsomTable__PaginationContainer">
      <div>
        <p class="AsomTable__Label" v-if="totalFilteredItems">
          Showing
          <span class="AsomTable__Label--highlighted">{{
            firstShownItemIndex + 1
          }}</span>
          to
          <span class="AsomTable__Label--highlighted">{{
            lastShownItemIndex + 1
          }}</span>
          of
          <span class="AsomTable__Label--highlighted">{{
            totalFilteredItems
          }}</span>
          results
        </p>
      </div>
      <asom-pagination
        v-if="totalFilteredItems > innerItemsPerPage"
        :total-items="totalFilteredItems"
        :items-per-page="innerItemsPerPage"
        :modelValue="currentPage"
        @update:modelValue="setCurrentPage"
      />
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import { toRefs, watch, onMounted } from "vue";
import usePagination from "./compositions/usePagination";
import useItemsPerPage from "./compositions/useItemsPerPage";
import useSortedColumn from "./compositions/useSortedColumn";
import tableMixins from "./tableMixins";

import getTextContentRow from "./helpers/getTextContentRow";

import AsomPagination from "../pagination/AsomPagination";
import AsomInputSelect from "../input/select/AsomInputSelect";
import AsomInputText from "../input/text/AsomInputText";
import AsomIcon from "../icon/AsomIcon";

export default {
  name: "AsomClientTable",
  components: {
    AsomPagination,
    AsomInputSelect,
    AsomInputText,
    AsomIcon,
  },
  mixins: [tableMixins],
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { data, columns, itemsPerPage } = toRefs(props);
    const { currentPage, setCurrentPage, resetCurrentPage } = usePagination();
    const { innerItemsPerPage, setInnerItemsPerPage } = useItemsPerPage();
    const { sortedData, toggleSortingColumn } = useSortedColumn();

    watch(data, resetCurrentPage);
    watch(columns, resetCurrentPage);
    watch(innerItemsPerPage, resetCurrentPage);
    watch(itemsPerPage, (oldVal, newVal) => setInnerItemsPerPage(newVal));

    onMounted(() => {
      setInnerItemsPerPage(itemsPerPage.value);
    });

    return {
      currentPage,
      setCurrentPage,
      resetCurrentPage,
      innerItemsPerPage,
      setInnerItemsPerPage,
      sortedData,
      toggleSortingColumn,
    };
  },
  data() {
    return {
      query: "",
    };
  },
  watch: {
    query() {
      if (this.filterable && this.pagination) {
        this.resetCurrentPage();
      }
    },
    data() {
      if (this.pagination) {
        this.resetCurrentPage();
      }
    },
  },
  computed: {
    totalItems() {
      return this.data.length;
    },
    firstShownItemIndex() {
      if (this.pagination)
        return (this.currentPage - 1) * this.innerItemsPerPage;
      return 0;
    },
    lastShownItemIndex() {
      if (this.pagination)
        return this.currentPage * this.innerItemsPerPage < this.totalFilteredItems
          ? this.currentPage * this.innerItemsPerPage - 1
          : this.totalFilteredItems - 1;
      return this.totalFilteredItems;
    },
    filteredData() {
      if (this.filterable && this.query) {
        if (this.filter !== null) {
          return this.filter(this.data, this.query);
        } else
          return this.data
            .filter((row) => getTextContentRow(row, this.columns, this.searchableDateColumns, 
              this.searchableTimeColumns, this.searchableCurrencyColumns, this.nonSearchableColumns)
            .includes(this.query.toLowerCase())
          );
      }
      return this.data;
    },
    totalFilteredItems() {
      return this.filteredData.length;
    },
    shownData() {
      let shownData = this.filteredData;
      const sortedColumn = this.sortedData.column;
      if (this.sortableColumns.length && sortedColumn) {
        const sortedAsc = this.sortedData.ascending;
        if (sortedAsc)
          shownData = shownData.sort((a, b) =>
            a[sortedColumn] > b[sortedColumn]
              ? 1
              : a[sortedColumn] == b[sortedColumn]
              ? 0
              : -1
          );
        else
          shownData = shownData.sort((a, b) =>
            a[sortedColumn] > b[sortedColumn]
              ? -1
              : a[sortedColumn] == b[sortedColumn]
              ? 0
              : 1
          );
      }
      return shownData.slice(
        this.firstShownItemIndex,
        this.lastShownItemIndex + 1
      );
    },
  },
  methods: {
    onColumnClicked(columnName) {
      if (this.sortableColumns.includes(columnName))
        this.toggleSortingColumn(columnName);
    },
    isDeleted(row) {
      if (get(row, "deleted", false)) return `line-through`;
    },
  },
};
</script>
<style src="./table.css"></style>
<style scoped>
.AsomTable__ItemsPerPageSelect {
  width: 100px;
}
</style>
