<template>
  <div :class="[
    'AsomInputText__Container',
    `AsomInputText__Container--state-${state}`,
    disabled && `AsomInputText__Container--is-disabled`
  ]">
    <span class="AsomInputText__Prepend" v-if="prependText">{{prependText}}</span>
    <input
      class="AsomInputText"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :disabled="disabled"
      :readonly="readonly ? readonly : disabled"
    />
    <span class="AsomInputText__Append" v-if="appendText">{{appendText}}</span>
  </div>
</template>
<script>
import inputStateMixin from '../inputStateMixin'

export default {
  name: 'AsomInputText',
  inheritAttrs: false,
  mixins: [ inputStateMixin ],
  props: {
    'modelValue': {
      type: undefined
    },
    'prependText': {
      type: String,
    },
    'appendText': {
      type: String,
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'update:modelValue' ]
}
</script>
<style scoped>
.AsomInputText__Container {
  @apply mt-0 flex rounded-md shadow-sm;
}

.AsomInputText__Container :first-child {
  @apply rounded-l-md
}

.AsomInputText__Container :last-child {
  @apply rounded-r-md
}

.AsomInputText__Container--is-disabled .AsomInputText {
  @apply bg-gray-100;
}

.AsomInputText {
  @apply block px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border max-w-full w-full;
}



.AsomInputText[type='number'] {
  -moz-appearance: textfield;/*For FireFox*/
}

.AsomInputText[type=number]::-webkit-outer-spin-button,
.AsomInputText[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.AsomInputText__Container--state-default .AsomInputText {
  @apply border-gray-300 focus:border-blue-500 focus:ring-blue-500 focus:ring-2 focus:outline-none focus:ring-opacity-50 text-sm
}

.AsomInputText__Container--state-error .AsomInputText {
  @apply border-red-300 ring-red-500 bg-red-50 focus:border-red-500 focus:ring-red-500 focus:ring-2 focus:outline-none focus:ring-opacity-50  text-sm
}

.AsomInputText__Prepend {
  @apply inline-flex items-center px-3 border border-r-0 bg-gray-50 border-gray-300 text-gray-500 sm:text-sm;
}

.AsomInputText__Append {
  @apply inline-flex items-center px-3 border border-l-0 bg-gray-50 border-gray-300 text-gray-500 sm:text-sm
}
</style>